import React, { FC, Dispatch, SetStateAction ,useEffect} from 'react'
import Modal from '@mui/material/Modal'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import CloseIcon from 'assets/icons/CloseIcon.svg'
import XLogo from 'assets/icons/XLogo.png'
import { S } from './modal.styles'
interface Props {
  setType?: Dispatch<SetStateAction<number>>
  onClose: () => void
  shareUrl: any
  country?: any
}

const ShareModal: FC<Props> = ({ setType, onClose, shareUrl, country }) => {
  const message = country ?'Use my discounted eSIM plan for '+country+' from GIANT Protocol':'Your friend just gifted you free mobile data via GIANT and earned GIANT tokens. To use your data, simply click on the URL and scan the QR code. You can also earn GIANT by using or gifting data to anyone in 200+ countries.'
  return (
    <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <S.ShareModalMainContainer
        style={{
          padding: '2rem 2rem 1rem 2rem',
          minHeight: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <S.ShareModalTitle>Promote on your socials:</S.ShareModalTitle>
        <img
          src={CloseIcon}
          alt=""
          onClick={onClose}
          style={{ cursor: 'pointer', position: 'absolute', top: '0.5rem', right: '1rem' }}
        />
        <FacebookShareButton
          url={shareUrl}
          quote={message}
        >
          <FacebookIcon size={52} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={message}
        >
          <img src={XLogo} style={{ width: '51px'}}/>
        </TwitterShareButton>
        <LinkedinShareButton
          url={shareUrl}
          title="GIANTprotocol"
          summary={message}
        >
          <LinkedinIcon size={52} round={true} />
        </LinkedinShareButton>
        <EmailShareButton
          url={shareUrl}
          body={message}
        >
          <EmailIcon size={52} round={true} />
        </EmailShareButton>
      </S.ShareModalMainContainer>
    </Modal>
  )
}

export default ShareModal
