import { FC, useState } from 'react'
import { FormControlLabel, IconButton, useMediaQuery } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { DEVICE_TYPE } from 'utils/constants'
import { PURCHASE_TYPE_BUY } from 'utils/constants'
import { useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useModalContext } from 'contexts/modal.context'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { S } from './Scan.styles'
import { S as D } from '../../modal.styles'
import { checkDCTStatus } from 'utils/constants/config'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { userClient } from 'services/http/user.service'
import { useAppContext } from 'contexts/app.context'

const Scan: FC = () => {
  const { purchaseType, userLogin } = useWalletContext()
  const medium = useMediaQuery('(max-width:899.95px)')
  const { t } = useTranslation()
  const { activatedPurchasedata, walletPurchaseData, deviceType } = usePaymentContext()
  const { setActiveStep, setReceiverState, androidSteps, iosSteps, disableVerification, setAndroidSteps, setIosSteps } =
    useModalContext()
  const { selectedOfferType } = useAppContext()
  const location = useLocation()
  const [verificationLoading, setVerificationLoading] = useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAndroidSteps({
      ...androidSteps,
      [event.target.name]: event.target.checked,
    })
  }

  const handleIosStepChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIosSteps({
      ...iosSteps,
      [event.target.name]: event.target.checked,
    })
  }

  const { step1, step2, step3 } = androidSteps
  const { iosStep1, iosStep2, iosStep3 } = iosSteps
  const error = [step1, step2, step3].filter((v) => v).length < 3
  const iosError = [iosStep1, iosStep2, iosStep3].filter((v) => v).length < 3

  const qrData =
    activatedPurchasedata?.activationCode !== undefined
      ? 'data:image/png;base64,' + activatedPurchasedata?.activationCode
      : 'data:image/png;base64,' + walletPurchaseData?.activationCode

  const segmentData = (value: any) => {
    return {
      id: value?.id,
      dataLimitInBytes: value?.dataLimitInBytes,
      dataUsageRemainingInBytes: value?.dataUsageRemainingInBytes,
      isProviderPurchased: value?.isProviderPurchased,
      type: value?.type,
      poolId: value?.poolId,
      activationPeriod: value?.offer?.activationPeriod,
      active: value?.offer?.active,
      connectionType: value?.offer?.connectionType,
      currency: value?.offer?.currency,
      description: value?.offer?.description,
      name: value?.offer?.name,
      networkType: value?.offer?.networkType,
      providerId: value?.offer?.providerId,
      region: value?.offer?.region,
      retailPrice: value?.offer?.retailPrice,
      validFromActivation: value?.offer?.validFromActivation,
      paymentId: value?.paymentId.id,
      paidAmount: value?.paidAmount,
      paidChain: value?.paidChain,
      paidCurrency: value?.paidCurrency,
    }
  }
  const handleVerification = () => {
    setVerificationLoading(true)
    checkDCTStatus({
      id: activatedPurchasedata.id !== undefined ? activatedPurchasedata.id : walletPurchaseData?.id,
      poolId: activatedPurchasedata.id !== undefined ? activatedPurchasedata.poolId : walletPurchaseData?.poolId,
      token: userLogin,
    })
      .then((res) => {
        if (res.status === true) {
          if (purchaseType === PURCHASE_TYPE_BUY) {
            setActiveStep(2)
          } else {
            if (userLogin) {
              userClient.postUserEventsWithAuth(
                {
                  eventName: 'Verification Success popup',
                  properties: {
                    build: process.env.REACT_APP_CURRENT_CHAIN,
                    offer: activatedPurchasedata?.id
                      ? segmentData(activatedPurchasedata)
                      : segmentData(walletPurchaseData),
                    screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
                  },
                },
                userLogin,
              )
            }

            setReceiverState('SUCCESS')
          }
        } else {
          toast.error('Verification failed. Please ensure the one time setup is done!')
        }
        setVerificationLoading(false)
      })
      .catch((e) => {
        toast.error("Couldn't verify your plan.")
        setVerificationLoading(false)
      })
  }

  return (
    <S.ScanContainer>
      <S.ScanContent>
        <S.ScanQRContent>
          {deviceType === DEVICE_TYPE[0].value ? (
            <S.ScanTitle> {t('scan_modal.settings')} &gt; {t('scan_modal.network-internet')} </S.ScanTitle>
          ) : (
            <S.ScanTitle> {t('scan_modal.open-camera')} </S.ScanTitle>
          )}
          <S.MiniText>({t('scan_modal.one-time-setup')})</S.MiniText>
        </S.ScanQRContent>
        {medium && (
          <S.ScanQR medium={medium}>
            <S.QRWrapper>
              <img src={qrData} style={{ height: '90%' }} alt="qrimg" />
            </S.QRWrapper>
          </S.ScanQR>
        )}

        {deviceType === DEVICE_TYPE[0].value && (
          <S.CustomFormControl required error={error} component="fieldset" variant="standard">
            <S.CustomFormGroup>
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={step1}
                    onChange={(e: any) => disableVerification === false && handleChange(e)}
                    name="step1"
                  />
                }
                label= {t('scan_modal.checkbox.one')}
              />
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={step2}
                    onChange={(e: any) => disableVerification === false && handleChange(e)}
                    name="step2"
                  />
                }
                label= {t('scan_modal.checkbox.two')}
              />
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={step3}
                    onChange={(e: any) => disableVerification === false && handleChange(e)}
                    name="step3"
                  />
                }
                label= {t('scan_modal.checkbox.three')}
              />
            </S.CustomFormGroup>
          </S.CustomFormControl>
        )}

        {deviceType !== DEVICE_TYPE[0].value && (
          <S.CustomFormControl required error={error} component="fieldset" variant="standard">
            <S.CustomFormGroup>
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={iosStep1}
                    onChange={(e: any) => disableVerification === false && handleIosStepChange(e)}
                    name="iosStep1"
                  />
                }
                label='Enter "GIANT" as the label and set "Default line" as Primary'
              />
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={iosStep2}
                    onChange={(e: any) => disableVerification === false && handleIosStepChange(e)}
                    name="iosStep2"
                  />
                }
                label='Choose "GIANT" as your default line for "Cellular Data"'
              />
              <FormControlLabel
                control={
                  <S.CheckBoxStyles
                    checked={iosStep3}
                    onChange={(e: any) => disableVerification === false && handleIosStepChange(e)}
                    name="iosStep3"
                  />
                }
                label='Turn "Data Roaming" toggle ON inside the GIANT cellular plan'
              />
            </S.CustomFormGroup>
          </S.CustomFormControl>
        )}

        <S.VerifyButton>
          <PrimaryButton
            style={{ height: '50px', width: '250px', fontSize: '18px' }}
            disable={(deviceType === DEVICE_TYPE[0].value ? error : iosError) || verificationLoading}
            onClick={() => {
              if (userLogin) {
                userClient.postUserEventsWithAuth(
                  {
                    eventName: 'Clicked Verify Installation button',
                    properties: {
                      build: process.env.REACT_APP_CURRENT_CHAIN,
                      offer: activatedPurchasedata?.id
                        ? segmentData(activatedPurchasedata)
                        : segmentData(walletPurchaseData),
                      os: deviceType,
                      screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
                    },
                  },
                  userLogin,
                )
              }

              if (!verificationLoading && !disableVerification) handleVerification()
            }}
          >
            {verificationLoading ? <D.ContrastProgress /> : disableVerification ? 'Verified' : 'Verify Installation'}
          </PrimaryButton>
          <S.TroubleText>
            Having trouble? Visit our detailed&nbsp;
            <S.CustomLink
              onClick={async () => {
                if (userLogin) {
                  userClient.postUserEventsWithAuth(
                    {
                      eventName: 'Clicked installation guide link',
                      properties: {
                        build: process.env.REACT_APP_CURRENT_CHAIN,
                        offer: activatedPurchasedata?.id
                          ? segmentData(activatedPurchasedata)
                          : segmentData(walletPurchaseData),
                        screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
                      },
                    },
                    userLogin,
                  )
                }
                window.open(
                  'https://support.giantprotocol.org/hc/en-us/articles/9297603626519-How-do-I-install-and-activate-my-eSIM-',
                )
              }}
            >
              installation guide.{' '}
              <IconButton sx={{ padding: '3px' }}>
                <OpenInNewIcon sx={{ color: '#45B549', fontSize: '0.8rem' }} />
              </IconButton>
            </S.CustomLink>
          </S.TroubleText>
        </S.VerifyButton>
      </S.ScanContent>
      <S.ScanQR>
        <S.QRWrapper>
          <img src={qrData} style={{ height: '90%' }} />
        </S.QRWrapper>
      </S.ScanQR>
    </S.ScanContainer>
  )
}

export default Scan
