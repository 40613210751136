import React, { useState, useEffect, FC } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { S } from '../../modal.styles'

import AmericanExp from '../../../../assets/AmericanExp.svg'
import UnionPay from '../../../../assets/UnionPay.svg'
import Visa from '../../../../assets/Visa.svg'
import JCB from '../../../../assets/JCB.svg'
import Discover from '../../../../assets/Discover.svg'
import DinersClub from '../../../../assets/DinersClub.svg'
import Stripe from '../../../../assets/Stripe.svg'
import GiantDollar from '../../../../assets/icons/GiantDollor_Green.svg'
import { useTranslation } from 'react-i18next'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'
import { useAppContext } from 'contexts/app.context'
import { useModalContext } from 'contexts/modal.context'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { createCreditPayment, getPaymentPrice } from './buy.helper'

interface Props {
  segmentData: any
}
const CreditCardPaymentPage: FC<Props> = ({ segmentData }) => {
  const { userLogin, modalData, setShowPurchaseModal, purchaseType, userDetails, demandReward } = useWalletContext()
  const {
    paymentType,
    credits,
    userEmail,
    setUserEmail,
    isPaymentLoading,
    setIsPaymentLoading,
    checkoutUrl,
    setCheckoutUrl,
    checkoutSuccess,
    setCheckoutSuccess,
    purchaseOrderType
  } = usePaymentContext()
  const { selectedOfferType, isTopup } = useAppContext()
  const { topUpMetaData } = useModalContext()
  const [isValidEmail, setIsValidEmail] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const medium = useMediaQuery('(max-width:599.95px)')
  const packPrice = selectedOfferType ? Number(modalData?.resalePrice)?.toFixed(2) : Number(modalData?.retailPrice)?.toFixed(2)

  useEffect(() => {
    if (userDetails?.user?.email) setIsValidEmail(true)
  }, [userDetails])

  useEffect(() => {
    if (checkoutSuccess) {
      window.open(checkoutUrl, '_self')
      setShowPurchaseModal(false)
      setIsPaymentLoading(false)
      setCheckoutSuccess(false)
    }
  }, [checkoutSuccess])

  const handleEmail = (e: any) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      setIsValidEmail(true)
    } else setIsValidEmail(false)
    setUserEmail(e.target.value)
  }

  const handleCreditPayment = async () => {
    let purchaseId = isTopup?topUpMetaData?.id:null
    createCreditPayment(
      {
        navigate,
        userLogin,
        segmentData,
        userDetails,
        pathname,
        paymentType,
        modalData,
        purchaseType,
        selectedOfferType,
        isTopup,
        purchaseId,
        setIsPaymentLoading,
        userEmail,
        setCheckoutUrl,
        setCheckoutSuccess,
      },
      false,
    )
  }

  const showEmailField = !userDetails?.user?.email
  return (
    <S.CreditPaymentContainer showEmailField={showEmailField}>
      <S.CreditCardBdy>       
          <S.CreditCardBox>
            <S.PaymentIconContainer>
              <img src={AmericanExp} alt="AmericanExpress" />
              <img src={UnionPay} alt="UnionPay" />
              <img src={Visa} alt="Visa" />
              <img src={JCB} alt="jcb" />
              <img src={Discover} alt="Discover" />
              <img src={DinersClub} alt="DinersClup" />
            </S.PaymentIconContainer>
            <S.StripeContainer
              sx={{
                display: 'flex',
              }}
            >
              Powered by &nbsp;
              <img src={Stripe} alt="Stripe" />
            </S.StripeContainer>
          </S.CreditCardBox>              

        {showEmailField && (
          <S.EmailTextContent>
            <S.EmailInput
              placeholder="Enter email address"
              autoComplete="email"
              type="email"
              name="email"
              style={{ width: medium ? '100%' : '95%' }}
              value={userEmail}
              onChange={handleEmail}
            />
            <S.HelperText>(for eSIM QR code, payment receipt)</S.HelperText>
          </S.EmailTextContent>
        )}
      </S.CreditCardBdy>

      <S.CreditCardFooter>
        <S.RewardContents>
          <S.PayText>{t('shop_plancard-pay')} $ {getPaymentPrice(packPrice, credits)}</S.PayText>
          <S.EarnText>
          {t('shop_plancard-earn')}
            <img src={GiantDollar} alt="Giant Dollar" style={{ paddingLeft: '0.2rem' }} />{' '}
            {!selectedOfferType ? Number(modalData?.retailPrice * demandReward).toFixed(2) : '0.00'}
          </S.EarnText>
        </S.RewardContents>
        <PrimaryButton
          onClick={() => {
            if (!isPaymentLoading) handleCreditPayment()
          }}
          style={{
            margin: `${medium ? 'auto ' : 'none'}`,
            height: '2.5rem',
            width: `${medium ? '70% ' : '11.25rem'}`,
          }}
          disable={!isValidEmail || isPaymentLoading}
        >
          {isPaymentLoading ? <S.ContrastProgress /> : 'Next'}
        </PrimaryButton>
        <S.CreditCardPaymentText>On clicking Next you’ll be taken to a Stripe checkout page</S.CreditCardPaymentText>
      </S.CreditCardFooter>
    </S.CreditPaymentContainer>
  )
}

export default CreditCardPaymentPage
