import { FC, useEffect, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'

import { useModalContext } from 'contexts/modal.context'
import { convertToPascalCase, DateDifference, formatBytes, getPercentage } from 'utils'
import WorldIcon from 'assets/icons/WorldIcon.svg'
import { S } from './soldmodal.style'
import { useWalletContext } from 'contexts/wallet.context'
import moment from 'moment'
import GiantDollor from 'assets/icons/GiantDollor.svg'

const SoldModal: FC = () => {
  const { soldModalData: data, setShowSoldModal, showSoldModal } = useModalContext()
  const { allProviders } = useWalletContext()

  const [currentProviderDetails, setCurrentProviderDetails] = useState<any>({})

  useEffect(() => {
    if (allProviders?.length && data?.poolId) {
      let filteredData = getCurrentProviderDetails()
      setCurrentProviderDetails(filteredData)
    }
  }, [allProviders, data])

  const getCurrentProviderDetails = () => {
    return allProviders?.find(({ poolId }: any) => poolId === data?.poolId)
  }

  const getEstimatedExpiryDate = (endTime: any) => {
    let currentTimeAsMS = Date.now()
    let diff = DateDifference(endTime)
    return moment(currentTimeAsMS + 1000 * 60 * 60 * 24 * diff).format('MMM DD, YYYY')
  }

  return (
    <>
      <Modal open={showSoldModal} onClose={() => setShowSoldModal(false)}>
        <S.ModalContainer>
          <S.ModalHeader
            color={{
              gradientStart: data?.countryColor?.gradientStart,
              gradientEnd: data?.countryColor?.gradientEnd,
            }}
          >
            <S.ModalHeaderLeft>
              <S.DataLimitContainer>
                <S.DataValue>
                  <S.DataSize>{formatBytes(data?.dataLimitInBytes).value}</S.DataSize>
                  <S.DataLimit>{formatBytes(data?.dataLimitInBytes).size}</S.DataLimit>
                </S.DataValue>
                <S.DataValidity>
                  | {data?.offer?.validFromActivation} {data?.offer?.validFromActivation === '1' ? 'Day' : 'Days'}
                </S.DataValidity>
              </S.DataLimitContainer>
            </S.ModalHeaderLeft>
            <S.ModalHeaderRight>
              <S.ModalHeaderRightTitle>
                <S.HeadCountryTitle>{data?.country}</S.HeadCountryTitle>
                {data?.flagCountryCode === 'Global' ? (
                  <img
                    src={WorldIcon}
                    alt="currency img"
                    style={{ marginLeft: '0.5rem', width: '28px', height: '28px' }}
                  />
                ) : (
                  <img
                  src={`https://giant-protocol.github.io/country-flag-icons/3x2/${data?.flagCountryCode?.toUpperCase()}.svg`}
                    alt="currency img"
                    style={{ marginLeft: '0.5rem', width: '28px', height: '28px' }}
                  />                  
                )}
              </S.ModalHeaderRightTitle>
              <CloseIcon
                onClick={() => setShowSoldModal(false)}
                sx={{ backgroundColor: 'white', borderRadius: '50%', width: '25px', cursor: 'pointer' }}
              />
            </S.ModalHeaderRight>
          </S.ModalHeader>
          <S.ModalBodyContainer>
            <S.TopContainer>
              <S.StatusContainer sx={{ display: 'flex', alignItems: 'center' }}>
                Status : &nbsp;
                <Box
                  sx={{ height: '15px', width: '15px', borderRadius: '50%', background: `${data?.dctStatusId?.color}` }}
                ></Box>
                &nbsp;{data?.dctStatusId?.status && convertToPascalCase(data?.dctStatusId?.status)}
              </S.StatusContainer>
              <S.GrayContainer>
                <S.InnerContainer>
                  {!data?.resaleQuantityOfDataInBytes ? (
                    <S.SoldGreenText>
                      Sold {formatBytes(data?.dataUsageRemainingInBytes).value}{' '}
                      {formatBytes(data?.dataUsageRemainingInBytes).size} with {DateDifference(data?.endTime)} days left
                      at ${data?.resalePrice}
                    </S.SoldGreenText>
                  ) : (
                    <S.SoldGreenText>
                      Sold {formatBytes(data?.resaleQuantityOfDataInBytes).value}{' '}
                      {formatBytes(data?.resaleQuantityOfDataInBytes).size} with {DateDifference(data?.endTime)} days
                      left at ${data?.resalePrice}
                    </S.SoldGreenText>
                  )}

                  <S.ProviderText>
                    Provider: &nbsp;
                    <img
                      src={`data:image/png;base64,${currentProviderDetails?.logo}`}
                      alt="provider logo"
                      style={{ width: '21px', height: '21px' }}
                    />
                    &nbsp; {currentProviderDetails?.displayName}
                  </S.ProviderText>
                </S.InnerContainer>
                <S.InnerContainer>
                  <S.RewardsContainer>
                    Est. Rewards:&nbsp;
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={GiantDollor} style={{ height: '0.9rem' }} alt="" />
                      &nbsp;
                      {data?.estimated_dct_reward?.amount}
                    </Box>
                  </S.RewardsContainer>
                  <S.ExpiryText>Expires: {getEstimatedExpiryDate(data?.endTime)}</S.ExpiryText>
                </S.InnerContainer>
              </S.GrayContainer>
            </S.TopContainer>
            <S.BottomContainer>
              {!data?.resaleQuantityOfDataInBytes ? (
                <Typography>
                  Plan is sold for ${data?.resalePrice} credits and it had{' '}
                  {formatBytes(data?.dataUsageRemainingInBytes).value}{' '}
                  {formatBytes(data?.dataUsageRemainingInBytes).size} with {DateDifference(data?.endTime)} days to
                  expire.
                </Typography>
              ) : (
                <Typography>
                  Plan is sold for ${data?.resalePrice} credits and it had{' '}
                  {formatBytes(data?.resaleQuantityOfDataInBytes).value}{' '}
                  {formatBytes(data?.resaleQuantityOfDataInBytes).size} with {DateDifference(data?.endTime)} days to
                  expire.
                </Typography>
              )}

              <S.DistributionContainer>
                <S.DistributionHeader>Credits Distribution</S.DistributionHeader>
                <S.DistributionSubContainer>
                  <S.DistributionText>You (75%)</S.DistributionText>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '12%' }}>
                    <Typography>$</Typography>
                    <S.DistributionText sx={{ fontWeight: '700 !important' }}>
                      {parseFloat(getPercentage(75, Number(data?.resalePrice))).toFixed(2)}
                    </S.DistributionText>
                  </Box>
                </S.DistributionSubContainer>
                <S.DistributionSubContainer>
                  <S.DistributionText>Provider (20%)</S.DistributionText>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '12%' }}>
                    <Typography>$</Typography>
                    <S.DistributionText sx={{ fontWeight: '700 !important' }}>
                      {parseFloat(getPercentage(20, Number(data?.resalePrice))).toFixed(2)}
                    </S.DistributionText>
                  </Box>
                </S.DistributionSubContainer>
                <S.DistributionSubContainer>
                  <S.DistributionText>Protocol fee (5%)</S.DistributionText>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '12%' }}>
                    <Typography>$</Typography>
                    <S.DistributionText sx={{ fontWeight: '700 !important' }}>
                      {parseFloat(getPercentage(5, Number(data?.resalePrice))).toFixed(2)}
                    </S.DistributionText>
                  </Box>
                </S.DistributionSubContainer>
              </S.DistributionContainer>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '0.2rem' }}>
                <InfoIcon style={{ cursor: 'pointer', color: '#979797', height: '18px' }} />
                <S.InfoText>
                  The resale value is distributed between 3 parties. You the owner, provider and Protocol at the end of
                  the plan expiry.
                </S.InfoText>
              </Box>
            </S.BottomContainer>
            <S.SoldBtn disabled>SOLD</S.SoldBtn>
          </S.ModalBodyContainer>
        </S.ModalContainer>
      </Modal>
    </>
  )
}

export default SoldModal
