import { FC, useEffect, useState } from 'react'
import { Box, CircularProgress, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useGetStakeModalData } from 'hooks/getStakeModalData.hook'
import PrimaryButton from 'components/buttons/PrimaryButton'
import ProviderDropDown from 'components/dropdown/providerdropdown'
import { S } from './stakemodal.style'
import { stakingClient } from 'services/http/staking.service'
import { useWalletContext } from 'contexts/wallet.context'
import { useQuery } from 'react-query'
import { handleStake, handleUnstake } from 'polkadot/sendmethods/staking'
import StakeTab from './staketab'
import { useModalContext } from 'contexts/modal.context'
import { toDecimal } from 'utils'

interface Props {
  show: boolean
  type: string
}

const StakeModal: FC<Props> = ({ show, type }) => {
  // Context
  const { userLogin, connectedAccount, assetMetadata, userDetails, allProviders, balance } = useWalletContext()
  const { setShowStakeModal, setFetchMyProvidersList } = useModalContext()

  // Hooks
  const { providerList, selectedProvider, setSelectedProvider, amount, setAmount, rowsCount, setRowsCount } =
    useGetStakeModalData()

  const onClose = () => {
    setShowStakeModal({
      show: false,
      type: 'stake',
    })
  }

  // States
  const [history, setHistory] = useState<any>([])
  const [unstakeRequests, setUnstakeRequests] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [historyLoading, setHistoryLoading] = useState<boolean>(false)
  const [requestLoading, setRequestLoading] = useState<boolean>(false)
  const [showLoadmoreBtn, setShowLoadmoreBtn] = useState<boolean>(true)
  const [stakePage, setStakePage] = useState<number>(1)
  const [userToken, setUserToken] = useState<any>(false)
  const [providers, setProviders] = useState<any>([])
  const [selectedPoolStakedAmount, setSelectedPoolStakedAmount] = useState<any>()

  // Queries
  useQuery('getMyPageProvidersList', async () => await providers.getUserProviderList(connectedAccount.address), {
    cacheTime: 1000 * 60 * 5,
    onSuccess: (data) => {
      setProviders(data)
    },
    enabled: userToken,
    refetchOnWindowFocus: false,
  })

  const getStakeHistoryData = (page: number) => {
    setHistoryLoading(true)
    try {
      stakingClient.getStakingHistory(userLogin, type, page).then(async (res: any) => {
        if (res?.data?.length) {
          let manipulatedData = await res?.data?.map((history: any) => {
            let selectedProvider = allProviders.find((provider: any) => provider.poolId === history.poolId)

            return {
              ...history,
              selectedProvider,
            }
          })

          if (manipulatedData.length <= 0) {
            if (type === 'stake') {
              setShowLoadmoreBtn(false)
            }
            setHistoryLoading(false)
          } else {
            setHistoryLoading(false)
            stakePage > 1
              ? setHistory((prev: any) => {
                  return [...prev, ...manipulatedData]
                })
              : setHistory([...manipulatedData])
          }
        } else {
          setHistory([])
          setHistoryLoading(false)
        }
      })
    } catch (err) {
      setHistoryLoading(false)
      // console.log(err, 'error')
    }
  }

  const getUnstakeRequestData = (page: number) => {
    setRequestLoading(true)
    try {
      stakingClient.getUnstakeRequestQues(userLogin, page).then(async (res: any) => {
        if (res.data?.unStakeQueues.length) {
          let manipulatedData = await res.data?.unStakeQueues?.map((history: any) => {
            let selectedProvider = allProviders.find((provider: any) => provider.poolId === history.poolId)

            return {
              ...history,
              selectedProvider,
            }
          })
          if (manipulatedData?.length <= 0) {
            setRequestLoading(false)
            setShowLoadmoreBtn(false)
          } else {
            setRequestLoading(false)
            stakePage > 1
              ? setUnstakeRequests((prev: any) => [...prev, ...manipulatedData])
              : setUnstakeRequests([...manipulatedData])
          }
        } else {
          setUnstakeRequests([])
          setRequestLoading(false)
        }
      })
    } catch (err) {
      setRequestLoading(false)
      // console.log(err, 'error')
    }
  }

  // Effects
  useEffect(() => {
    if (connectedAccount.address !== undefined && userLogin !== null) {
      setUserToken(true)
    } else {
      setUserToken(false)
    }
  }, [connectedAccount.address, userLogin])

  useEffect(() => {
    if (show) {
      getStakeHistoryData(1)
      if (type === 'unstake') {
        getUnstakeRequestData(1)
      }
    }
  }, [show])

  useEffect(() => {
    setHistory([])
    setShowLoadmoreBtn(true)
  }, [type])

  useEffect(() => {
    if (providers.length > 0 && selectedProvider?.value !== '') {
      getSelectedProviderStakedBalance()
    }
  }, [providers, selectedProvider?.value])

  const getSelectedProviderStakedBalance = async () => {
    let temp = await allProviders.find((provider: any) => provider.displayName === selectedProvider?.value)
    await providers.forEach((provider: any) => {
      if (parseInt(provider.poolId) === temp.poolId) {
        let finalValue = toDecimal(provider?.providerDetails?.total, 12).deci
        setSelectedPoolStakedAmount(finalValue)
      }
    })
  }

  const handleStaking = () => {
    handleStake({
      stakeAmount: amount,
      account: connectedAccount,
      loading: setIsLoading,
      metaData: assetMetadata,
      poolId: selectedProvider?.poolId,
      setAmount: setAmount,
      getStakeHistoryData: getStakeHistoryData,
      setSelectedProvider: setSelectedProvider,
      location: 'stakemodal',
      setFetchMyProvidersList: setFetchMyProvidersList,
    })
  }

  const handleUnstaking = () => {
    handleUnstake(
      amount,
      connectedAccount,
      setIsLoading,
      assetMetadata,
      selectedProvider?.poolId,
      setAmount,
      getUnstakeRequestData,
      setSelectedProvider,
      selectedProvider,
      userLogin,
      userDetails,
      setFetchMyProvidersList,
    )
  }

  const fetchNextPage = async (type: string) => {
    setStakePage((prev: number) => prev + 1)
    if (type === 'stake') {
      getStakeHistoryData(stakePage + 1)
    } else if (type === 'request') {
      getUnstakeRequestData(stakePage + 1)
    }
  }

  return (
    <Modal open={show}>
      <S.ModalMainContainer>
        <S.HeaderContainer>
          <S.StakeModalHeader>
            <S.HeaderText>{type === 'stake' ? 'Stake' : 'Unstake'} </S.HeaderText>
            <S.CloseIconContainer onClick={onClose}>
              <CloseIcon style={{ color: '#ffffff', height: '30px', width: '30px' }} />
            </S.CloseIconContainer>
          </S.StakeModalHeader>
          <S.StakeModalSubHeader>
            <S.SubText>Choose provider and amount</S.SubText>
            <ProviderDropDown
              selectedValue={selectedProvider}
              setSelectedValue={setSelectedProvider}
              options={providerList}
            />
            <S.CustomInput placeholder="Min 1 GIANT" value={amount} onChange={(e: any) => setAmount(e.target.value)} />
            {type === 'stake' ? (
              <PrimaryButton
                style={{ height: '2.75rem', width: '9.625rem' }}
                onClick={() => handleStaking()}
                disable={
                  amount === '' ||
                  amount === '0' ||
                  parseFloat(amount) < 1 ||
                  selectedProvider?.value === '' ||
                  parseFloat(amount) > parseFloat(balance?.deci)
                }
              >
                {isLoading ? <CircularProgress color="success" size={25} /> : 'CONFIRM'}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                style={{ height: '2.75rem', width: '9.625rem' }}
                onClick={() => handleUnstaking()}
                disable={
                  amount === '' ||
                  amount === '0' ||
                  parseFloat(amount) < 1 ||
                  selectedProvider?.value === '' ||
                  parseFloat(amount) > parseFloat(selectedPoolStakedAmount)
                }
              >
                {isLoading ? <CircularProgress color="success" size={25} /> : 'CONFIRM'}
              </PrimaryButton>
            )}
          </S.StakeModalSubHeader>
        </S.HeaderContainer>

        <S.StakeModalBody>
          {/* {type === 'unstake' && (
            <>
              <S.StakeHistoryHeader>
                <S.HeaderText>Unstake Requests</S.HeaderText>
                <ShowRows count={rowsCount} setCount={setRowsCount} />
              </S.StakeHistoryHeader>
              <RequestTable data={history} />
            </>
          )}
          <S.StakeHistoryHeader>
            <S.HeaderText>{type === 'stake' ? 'Stake' : 'Unstake'} History</S.HeaderText>
            <ShowRows count={rowsCount} setCount={setRowsCount} />
          </S.StakeHistoryHeader>
          {stakeHistoryLoading || isFetching ? (
            <Box sx={{ display: 'grid', placeContent: 'center', height: '10vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <HistoryTable data={history} type={type} />
          )} */}

          <StakeTab
            loader={historyLoading}
            requestLoader={requestLoading}
            history={history}
            type={type}
            unstakeRequests={unstakeRequests}
            fetchNextPage={fetchNextPage}
            showLoadmoreBtn={showLoadmoreBtn}
            getUnstakeRequestData={getUnstakeRequestData}
          />
        </S.StakeModalBody>
      </S.ModalMainContainer>
    </Modal>
  )
}

export default StakeModal
