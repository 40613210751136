import React, { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link, Box, Modal, useMediaQuery, CircularProgress, IconButton } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { S } from './modal.styles'
import styled from '@emotion/styled'
import { useModalContext } from 'contexts/modal.context'
import { appClient } from 'services/http/app.service'
import CloseIcon from '@mui/icons-material/Close'
import PrimaryButton from 'components/buttons/PrimaryButton'
import SuccessIllustration from 'assets/PaymentSuccessIllustration.svg'
import FailedIllustration from 'assets/GiftFailedIllustration.svg'

const VerificationModal: React.FC = () => {
  const { openVerificationModal, setOpenVerificationModal } = useModalContext()
  const mobileView = useMediaQuery('(max-width:600px)')
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [verificationLoading, setVerificationLoading] = useState<boolean>(true)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const medium = useMediaQuery('(max-width:899.95px)')

  const handleClose = () => {
    setOpenVerificationModal(false)
    setSuccessMessage('')
    setErrorMessage('')
    navigate('/shop')
  }
  const verify = async (id:string) => {
    try {
      let message = await appClient.verification(id)
      setVerificationLoading(false)
      console.log(message?.status)
      if(message?.status){
        setSuccessMessage(message?.message)
      }else{
        setErrorMessage(message?.message)
      }
      console.log(message)
    } catch (error:any){
      setVerificationLoading(false)
      setErrorMessage(error?.message)
      console.log(error?.message)
    }

  }

  useEffect(() => {
    if (openVerificationModal&&pathname.split('/').includes('verification')) {
      const id = pathname.split('/')[2]
      if (id !== undefined && id !== '') {
        verify(id)
      } 
    }
  }, [openVerificationModal])

  return (
    <Modal open={openVerificationModal}>
      <S.ModalMainContainer>
        <S.ModalBody sx={{ minHeight: '10rem', display: 'grid',}}>
          <S.CustomIconButton
            sx={{ display: 'flex', justifySelf: 'flex-end', alignSelf: 'baseline' , marginRight:'10px', marginTop:'10px'}}
          >
           <CloseIcon
            sx={{
              borderRadius: '50%',
              border: '1px solid #DEE7FD',
              background: '#FFFFFF',
              color: 'black',
              padding: '5px !important',
            }}
            onClick={() => handleClose()}
          />
          </S.CustomIconButton>
          {verificationLoading ? ( <S.WarningText sx={{width: '50vh',justifyContent: 'center'}}><CircularProgress size={30} /></S.WarningText> ) :(
          <VerifyContainer sx={{ gap: medium && '10rem !important' }} successMessage ={successMessage}>
            <VerifyContentContainer sx={{ paddingTop: medium ? '0' : '1rem', gap: medium && '3rem !important' }}>
               <VerifyTitleText>{successMessage?'Email verified successfully!':errorMessage?'Something is not right.':''}</VerifyTitleText>
                <GiftSuccessUpdatedImgMobile src={successMessage?SuccessIllustration:FailedIllustration} />
                {errorMessage&&<><VerifyText>Please check your email and try again</VerifyText>
                <VerifyText>
                Still having problems? &nbsp;
                <ContactLink href={"https://support.giantprotocol.org/hc/en-us/requests/new"} >
                  Contact us{' '}
                  <IconButton sx={{ padding: '3px' }}>
                    <OpenInNewIcon sx={{ color: '#45B549', fontSize: '0.8rem' }} />
                  </IconButton>
                </ContactLink>
              </VerifyText></>}
            </VerifyContentContainer>            
          <PrimaryButton
            style={{ height: '50px', width: '11.375rem' }}
            onClick={() => { handleClose() }}
          >
            {successMessage?'Shop and Earn':'Back'}
          </PrimaryButton>
          <GiftSuccessUpdatedImg src={successMessage?SuccessIllustration:FailedIllustration} />
        </VerifyContainer>)}          
        </S.ModalBody>
      </S.ModalMainContainer>
    </Modal>
  )
}
const VerifyContainer = styled(Box)(({ theme, successMessage }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: successMessage?'center':'space-between',
  flexWrap: 'wrap',
  padding: '20px 35px 35px 50px',
  boxSizing: 'border-box',
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '20rem',
  gap: successMessage?'3rem':'2rem',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    height: '87vh',
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    height: 'auto',
    overflow:'auto'
  },
}))
const VerifyContentContainer = styled(Box)(({ theme }: any) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.625rem',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))
const VerifyTitleText = styled(Box)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '31px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}))
const GiftSuccessUpdatedImgMobile = styled.img(({ theme }: any) => ({
  height: '15.0625rem',
  width: '25rem',
  bottom: '0',
  display: 'block',
  right: '-2rem',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
}))
const VerifyText = styled(Box)(({ theme }: any) => ({
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}))
const GiftSuccessImg = styled.img(({ theme }: any) => ({
  position: 'absolute',
  right: '0',
  height: '20rem',
  width: '20rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
const GiftSuccessUpdatedImg = styled(GiftSuccessImg)(({ theme }: any) => ({
  width: '30rem',
  bottom: '0',
  display: 'block',
  right: '-2rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
const ContactLink = styled(Link)(({ theme }: any) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontSize: '700',
}))
export default VerificationModal