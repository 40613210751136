import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace paymentClient {
  export const getPaymentsById = async (id: any, token: any) => {
    const response = await httpClient.get(`paymentsV2/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const getCumulativeRewards = async () => {
    const response = await httpClient.get(`v2/total-rewards/cumulative`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response
  }

  export const postEmailForUrl = async (data: any, token: any) => {
    const response = await httpClient.post(
      `mail/payment`,
      { data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const createCheckout = async (paymentId: any, email: any, token: any) => {
    return await httpClient.post(
      `payment/createCheckout/${paymentId}?email=${email}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  export const generateCode = async (purchaseId: any, token: any) => {
    return await httpClient.get(`getDCTCode/${purchaseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  export const getCryptos = async (token: any) => {
    return await httpClient.get(`payment-options-crypto-enums`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
