import { useAppContext } from 'contexts/app.context'
import { useModalContext } from 'contexts/modal.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { offerClient } from 'services/http/offers.service'
import { paymentClient } from 'services/http/payment.service'
import { purchaseclient } from 'services/http/purchases.service'
import { PURCHASE_TYPE_BULK_BUY,PURCHASE_TYPE_SINGLE_BUY } from 'utils/constants'

export const usePaymentConfirmation = ({ paymentIdValue, handleClose }: any) => {
  const [isPaymentLoading, setIsPaymentLoading] = useState(true)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
  const [mintStatus, setMintStatus] = useState<string | null>()
  const [mintRetryCount, setMintRetryCount] = useState(0)
  const [isFinalized, setIsFinalized] = useState(false)
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const [purchaseRetryCount, setPurchaseRetryCount] = useState(0)
  const [providerPurchaseStatus, setProviderPurchaseStatus] = useState<string | null>()
  const [userToken, setUserToken] = useState<any>(false)

  const {
    paymentId,
    handleWalletPurchaseData,
    handleOfferData,
    setFilteredOffer,
    setPurchaseModalLoading,
    apiRegions,
    setPaymentSuccess,
    handleCurrentLoggedUserData,
    setPurchaseOrderType,
  } = usePaymentContext()
  const { setSelectedOfferType } = useAppContext()
  const { setPurchaseId, setOpenActivationFailureModal } = useModalContext()
  const { setPurchaseModalType, modalDataHandler, userLogin } = useWalletContext()
  const payId = paymentIdValue ?? paymentId
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  const { refetch: getPayment, isError: paymentError } = useQuery(
    ['paymentConfirmation', payId],
    () => paymentClient.getPaymentsById(payId, userLogin),
    {
      retry: 3,
      enabled: !!payId && userToken,
      onSuccess: (data) => {
        if (data?.isResalePack) {
          setSelectedOfferType(true)
        } else {
          setSelectedOfferType(false)
        }
        if(data?.purchaseQuantity>1){
          setPurchaseOrderType(PURCHASE_TYPE_BULK_BUY)
        }else{
          setPurchaseOrderType(PURCHASE_TYPE_SINGLE_BUY)
        }
        setPurchaseId(data?.purchaseId?.id)
        let urlStatus = true

        if (!!status) {
          urlStatus = status === 'failed' ? false : true
        }
        if (data?.purchaseId && urlStatus) {
          setIsPaymentSuccess(true)
          handleWalletPurchaseData(data?.purchaseId)
          handleCurrentLoggedUserData(data?.user)
          setPurchaseModalType(data?.purchaseId?.type)
          handleOfferData(data?.offerId !== undefined ? data?.offerId : data?.resaleOffer)
          if (!!data?.purchaseId?.id && data?.purchaseId?.isMinted === 'success') {
            if (data?.offerId !== undefined) {
              offerClient.getFileredOffers(`offerId=${data?.offerId?.offerId}`).then((offerRes: any) => {
                setFilteredOffer(offerRes[0])
              })
            } else {
              offerClient.getFileredResaleOffers(userLogin, data?.resaleOffer?.id).then((offerRes: any) => {
                setFilteredOffer(offerRes?.data)
              })
            }
          }
          setPurchaseModalLoading(false)
          switch (data?.purchaseId?.isMinted) {
            case 'init':
              setMintStatus('init')
              break
            case 'success':
              setMintStatus('success')
              break
            case 'failed':
              setMintRetryCount((prevState) => prevState + 1)
              setMintStatus('failed')
              break
            default:
              setMintStatus('init')
          }
        } else {
          let regionId = data?.offerId !== undefined ? data.offerId.regionId : data?.resaleOffer?.regionId
          let offerId = data?.offerId !== undefined ? data.offerId.id : data?.resaleOffer?.id
          let manipulatedRegionData = apiRegions.filter((region: any) => region.id === regionId)
          let manipulatedOfferData = manipulatedRegionData[0]?.offers.filter((offer: any) => offer?.id === offerId)
          let finalManipulatedData = {
            ...manipulatedOfferData[0],
            color: manipulatedRegionData[0].countryColor.metadata,
            country: manipulatedOfferData[0].region,
            flagCountryCode: manipulatedRegionData[0].flagCountryCode,
          }
          modalDataHandler({
            data: finalManipulatedData,
          })
          setPurchaseModalLoading(false)
          setIsPaymentSuccess(false)
          setPaymentSuccess(false)
          // setReceiverState('FAILURE')
        }
      },
      onError: () => {
        toast.error('Payment failed')
        handleClose()
      },
    },
  )

  useEffect(() => {
    userLogin && setUserToken(true)
  }, [userLogin])

  useEffect(() => {
    let interval: any
    if (mintStatus === 'failed' && mintRetryCount >= 4) {
      setIsPaymentFailed(true)
      setIsPaymentLoading(false)
      clearInterval(interval)
    }
    if (!isPaymentSuccess || (mintStatus !== 'success' && mintRetryCount < 4 && !isPaymentFailed)) {
      var startTime = new Date().getTime()
      interval = setInterval(() => {
        if(new Date().getTime() - startTime > 18000){
          clearInterval(interval)          
          setOpenActivationFailureModal(true)
          setIsPaymentLoading(false)
          setIsFinalized(true)
        }
        getPayment()
      }, 4000)
    } else if (isPaymentSuccess && mintStatus === 'success') {
      setIsPaymentLoading(false)
      setIsFinalized(true)
    } else {
      setIsPaymentFailed(true)
      setIsPaymentLoading(false)
      setIsFinalized(false)
    }
    return () => clearInterval(interval)
  }, [getPayment, isPaymentSuccess, mintStatus, mintRetryCount])

  useEffect(() => {
    let interval: any
    if (mintStatus === 'failed' && mintRetryCount < 4 && userLogin !== null) {
      interval = setInterval(() => {
        purchaseclient.retryMint(payId, userLogin)
      }, 4000)
    }
    return () => clearInterval(interval)
  }, [mintStatus, mintRetryCount, payId, userLogin])

  return {
    isPaymentLoading,
    isPaymentSuccess,
    isFinalized,
    isPaymentFailed,
    mintStatus,
    mintRetryCount,
    providerPurchaseStatus,
    purchaseRetryCount,
    paymentError,
  }
}
