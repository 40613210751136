import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace purchaseclient {
  export const getFilteredPurchases = async (filter: string, token?: any) => {
    const response = await httpClient.get(`purchases?${filter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const retryMint = async (id: any, token: any) => {
    const response = await httpClient.get(`retryMint/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const feedbackStarReview = async (token: any) => {
    const response = await httpClient.get(`questions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const postFeedback = (data: any, token: any) => {
    return httpClient.post(
      'feedbacks',
      { ...data },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  export const updateFeedback = async (id: any, data: any, token: any) => {
    const response = await httpClient.put(
      `feedbacks/${id}`,
      { ...data },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const PurchashesIdGetFeedback = async (token: any) => {
    const response = await httpClient.get('account-user', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const getPurchaseByCode = (code: any, token: any) => {
    return httpClient.get(`getPurchase/${code}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
  export const getPurchaseByDctId = (dctId: string) => {
    return httpClient.get(`getPurchaseByDctId/status/${dctId}` )
  }
  export const getTotalDataSold = async (token: any) => {
    const res = await httpClient.get('totalDataSold', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return `${res.dataSold} ${res.unit}`;
  }
}
