import React, { useState, useEffect } from 'react'
import { Modal, Box, CircularProgress } from '@mui/material'

import { useModalContext } from 'contexts/modal.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'
import { PURCHASE_TYPE_BUY } from 'utils/constants'

import ReviewStar from '../../../assets/icons/PostReviewStar.svg'
import ReviewStarGreen from '../../../assets/icons/PostReviewGreen.svg'

import { FEEDBACK_STARS, FEEDBACK_CAROUSEL } from 'utils/constants'
import { purchaseclient } from '../../../services/http/purchases.service'
import { P } from './PurchaseSurvey.styles'
import { setSourceMapRange } from 'typescript'
const PurchaseSurvey: React.FC = () => {
  // Contexts
  const {
    postPurchaseSurvey,
    setpostPurchaseSurvey,
    feedbackState,
    setFeedbackState,
    getPurchaseId,
    setActivateNow,
    activateNow,
  } = useModalContext()
  const { walletPurchaseData, paymentSuccess } = usePaymentContext()
  const { userLogin, userDetails, setShowPurchaseModal, showPurchaseModal } = useWalletContext()
  //  States
  const [carouselValue, setCarouselValue] = useState(2)
  const [feedbackQuestion, setFeedbackQuestion] = useState<any>({})
  const [feedbackId, setFeedbackId] = useState<any>('')
  const [feedbackData, setFeedbackData] = useState<any>({
    type: 'payment-feedback',
    user: userDetails?.user?.id,
    stars: 0,
    questions: {
      q1: '',
      a1: '',
      q2: '',
      a2: '',
    },
    purchaseId: null,
  })

  useEffect(() => {
    if (userLogin !== null) {
      purchaseclient.feedbackStarReview(userLogin).then((questions: any) => {
        setFeedbackQuestion(questions)
      })
    }
  }, [userLogin])

  const handleNext = () => {
    let isBad = feedbackData?.stars <= 3

    if (feedbackData?.stars > 0) {
      if (carouselValue === 2) {
        if (isBad) {
          setFeedbackState('STAR_RATE_POOR')
          setCarouselValue((prev) => prev + 1)
        } else {
          setFeedbackState('STAR_RATE_GOOD')
          setCarouselValue((prev) => prev + 1)
        }
        setFeedbackData((prev: any) => {
          return {
            ...prev,
            questions: {
              ...prev.questions,
              q1: isBad ? feedbackQuestion.paymentFeedback.bq1 : feedbackQuestion.paymentFeedback.gq1,
              q2: isBad ? feedbackQuestion.paymentFeedback.bq2 : feedbackQuestion.paymentFeedback.gq2,
            },
            purchaseId: getPurchaseId,
            user: userDetails?.user?.id,
          }
        })
      } else {
        setCarouselValue(4)
        setFeedbackState('STAR_RATE_REVIEW')
      }

      carouselValue === 2 && handlePostFeedback()
      carouselValue === 3 && handleUpdateFeeedback()
      carouselValue === 4 && handleUpdateFeeedback()
    }
  }

  const handleTextInput = (e: any) => {
    setFeedbackData((prev: any) => {
      return {
        ...prev,
        questions: {
          ...prev.questions,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  const handlePostFeedback = () => {
    purchaseclient.postFeedback(feedbackData, userLogin).then((res: any) => {
      setFeedbackId(res.id)
    })
  }

  const handleUpdateFeeedback = () => {
    purchaseclient.updateFeedback(feedbackId, feedbackData, userLogin)
    if (carouselValue === 4) {
      if (paymentSuccess) {
        setActivateNow(true)
        setShowPurchaseModal(true)
      }

      setpostPurchaseSurvey(false)
    }
  }

  const handleClose = () => {
    setpostPurchaseSurvey(false)
    if (paymentSuccess) {
      setActivateNow(true)
      setShowPurchaseModal(true)
    }
  }

  const getDisableCondition = () => {
    if (carouselValue === 3) {
      return feedbackData?.stars >= 3
        ? feedbackData?.questions?.a1?.length === 0
        : feedbackData?.questions?.a2?.length === 0
    } else {
      if (carouselValue === 4) {
        return feedbackData?.stars >= 3
          ? feedbackData?.questions?.a2?.length === 0
          : feedbackData?.questions?.a1?.length === 0
      }
    }
  }

  return (
    <Modal open={postPurchaseSurvey}>
      <P.PurchaseSurveyContainer>
        <P.PurchaseSurvey>
          <P.SkipSurveyContainer>
            <P.SubContainerHeader onClick={() => handleClose()}>Skip survey</P.SubContainerHeader>
          </P.SkipSurveyContainer>
          <P.SubContainer>
            {feedbackState === '' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.mq} </P.SubContainerTitle>
                <P.StarReview>
                  {FEEDBACK_STARS.map((stars: any, index: any) => {
                    return (
                      <Box
                        key={index}
                        onClick={() =>
                          setFeedbackData((prev: any) => {
                            return {
                              ...prev,
                              stars: index + 1,
                            }
                          })
                        }
                      >
                        {feedbackData?.stars > index ? (
                          <img src={ReviewStarGreen} alt="star icon filled" />
                        ) : (
                          <img src={ReviewStar} alt="star icon" />
                        )}
                      </Box>
                    )
                  })}
                </P.StarReview>
              </>
            )}

            {feedbackState === 'STAR_RATE_POOR' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.bq1}</P.SubContainerTitle>

                <P.FeedbackInput
                  placeholder="Type your answer here"
                  maxLength={300}
                  type="text"
                  name="a1"
                  value={feedbackData.questions.a1}
                  required
                  onChange={handleTextInput}
                />
              </>
            )}

            {feedbackState === 'STAR_RATE_GOOD' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.gq1}</P.SubContainerTitle>
                <P.FeedbackInput
                  placeholder="Type your answer here"
                  autoComplete="text"
                  type="text"
                  maxLength={300}
                  name="a1"
                  value={feedbackData.questions.a1}
                  onChange={handleTextInput}
                />
              </>
            )}

            {feedbackState === 'STAR_RATE_REVIEW' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.bq2}</P.SubContainerTitle>
                {feedbackData?.stars <= 3 ? (
                  <>
                    <P.FeedbackInput
                      maxLength={300}
                      placeholder="Type your answer here"
                      autoComplete="text"
                      type="text"
                      name="a2"
                      value={feedbackData.questions.a2}
                      onChange={handleTextInput}
                    />
                  </>
                ) : (
                  <P.FeedbackInput
                    placeholder="Type your answer here"
                    autoComplete="text"
                    type="text"
                    maxLength={300}
                    name="a2"
                    value={feedbackData.questions.a2}
                    onChange={handleTextInput}
                  />
                )}
              </>
            )}

            <P.DisableButton
              style={{ height: '3.125rem', width: '8.75rem' }}
              onClick={handleNext}
              disabled={getDisableCondition()}
            >
              Next{' '}
            </P.DisableButton>
            <P.CarouselContainer>
              {FEEDBACK_CAROUSEL.map((index) => {
                return (
                  <P.CarouselStep
                    key={index}
                    onClick={handleNext}
                    style={{ background: carouselValue > index ? '#18BC46' : '#DEE7FD' }}
                  ></P.CarouselStep>
                )
              })}
            </P.CarouselContainer>

            {feedbackQuestion === '' && (
              <P.SubContainer>
                <CircularProgress />
              </P.SubContainer>
            )}
          </P.SubContainer>
        </P.PurchaseSurvey>
      </P.PurchaseSurveyContainer>
    </Modal>
  )
}
export default PurchaseSurvey
