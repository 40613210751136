import React, { useEffect, useState,useReducer } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Modal, Typography, useMediaQuery, CircularProgress, Link } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import DetailsIllustration from 'assets/DetailsImg.svg'
import { S } from '../modal.styles'
import { API_STATE, useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useModalContext } from 'contexts/modal.context'
import GlobeIcon from 'assets/icons/GlobelIcon.svg'
import GiantDollorGreen from 'assets/icons/GreenGiantDollarIcon.svg'
import { BUY,
  countriesWithPlaceholderFlag,
  PAYMENT_TYPE,
  PURCHASE_TYPE_BUY,
  PURCHASE_TYPE_GIFT,
  BULK_PAYMENT_TYPE,
  PURCHASE_TYPE_BULK_BUY,
  PURCHASE_TYPE_SINGLE_BUY,
  PURCHASE_TYPE_TOPUP
} from 'utils/constants'
import Selectors from './Selectors'
import Scan from './scan'
import Earn from './earn'
import Buy from './buy'
import Gift from './gift'
import Receiver from './receiver'
import ConfirmModal from '../ConfirmModal'
import TopupPlan from './topup/TopupPlan'
import { activateDctV3, activateProvider, checkDCTStatus } from 'utils/constants/config'
import { toDecimal } from 'utils'
import { userClient } from 'services/http/user.service'
import PrimaryButton from 'components/buttons/PrimaryButton'
import CustomTooltip from 'components/tooltip'
import { paymentClient } from 'services/http/payment.service'
import { useAppContext } from 'contexts/app.context'
import { getCreditsFromChain } from 'polkadot/callmethods/credits'
import { createCreditPayment, getPaymentPrice } from './buy/buy.helper'
import { purchaseclient } from 'services/http/purchases.service'
import { resaleClient } from 'services/http/resale.service'
import { useTranslation } from 'react-i18next'

const PurchaseModal = () => {
  const {
    setShowPurchaseModal,
    showPurchaseModal,
    modalData,
    apiState,
    userLogin,
    purchaseType,
    connectedAccount,
    openConfirmModal,
    setPurchaseModalType,
    setOpenConfirmModal,
    allProviders,
    modalDataHandler,
    userDetails,
    isConnected,
    setConnectedModalOpen,
    modalCountryData,
    polkadotAccountAddress,
    isWagmiConnected
  } = useWalletContext()
  const {
    cryptos,
    purchaseModalLoading,
    paymentType,
    myPlanCardAction,
    setCryptoData,
    handleWalletPurchaseData,
    setPaymentSuccess,
    setPurchaseModalLoading,
    setPaymentId,
    walletPurchaseData,
    activationModalLocation,
    filteredOffer,
    setTransactionStatus,
    apiRegions,
    credits,
    setCredits,
    isPaymentLoading,
    setIsPaymentLoading,
    userEmail,
    setCheckoutUrl,
    setCheckoutSuccess,
    setPurchaseOrderType,
    setPurchaseQuantity,
    setPaymentType,
    purchaseOrderType
  } = usePaymentContext()

  const {
    cryptoPurchaseLoading,
    activeStep,
    receiverState,
    isModalDisabled,
    setActivateNow,
    setReceiverState,
    setActiveStep,
    setCryptoPurchaseLoading,
    setAndroidSteps,
    setIosSteps,
    setDisableVerificationButton,
    setDisableModal,
    setShowGuideModal,
    setbuyModalCloseSurvey,
    setShowNetworkModal,
    setShowTopUpModal,
  } = useModalContext()
  const { setSelectedOfferType, selectedOfferType, setIsTopup, isTopup, setRefetchMydata } = useAppContext()
  const [selectedPayment, setSelectedPayment] = useState<any>({})
  const [activationLoading, setActivationLoading] = useState<boolean>(false)
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(true)
  const [regionData, setRegionData] = useState<any>({})
  const [filteredCoverage, setFilteredCoverage] = useState<any>([])

  const medium = useMediaQuery('(max-width:599.95px)')
  const medium1 = useMediaQuery('(max-width:899.95px)')
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const status = searchParams.get('status')
  const [eSimQuantity, setESimQuantity] = useState<any>(Number('1'));
  const packPrice = selectedOfferType ? Number(modalData?.resalePrice)?.toFixed(2) : Number(modalData?.retailPrice)?.toFixed(2)
  const hideCardPayment = (process.env.REACT_APP_CREDITCARD_PAYMENT === 'false'); 
  const hideCirclePayment = (process.env.REACT_APP_CIRCLE_PAYMENT === 'false'); 
  const hideCoinbasePayment = (process.env.REACT_APP_COINBASE_PAYMENT === 'false'); 
  const hideCTOPayment = (process.env.REACT_APP_CRYPTOPAY_PAYMENT === 'false'); 
  const enableBulkPurchase = (process.env.REACT_APP_ENABLE_BULK_PURCHASE === 'true'); 

  let segmentData = {
    name: modalData?.name,
    description: modalData?.description,
    activationPeriod: modalData?.activationPeriod,
    networkType: modalData?.networkType,
    offerId: modalData?.offerId,
    purchaseType: purchaseType === 'self' ? 'Buy' : 'Gift',
    poolId: modalData?.poolId,
    providerId: modalData?.providerId,
    quantityOfDataInBytes: modalData?.quantityOfDataInBytes,
    region: modalData?.region,
    country: modalData?.country,
    retailPrice: selectedOfferType ? modalData?.resalePrice : modalData?.retailPrice,
    validFromActivation: modalData?.validFromActivation,
  }

  const resetStates = () => {
    setPurchaseModalLoading(false)
    setActiveStep(BUY)
    setRegionData({})
    setReceiverState('')
    setPaymentSuccess(false)
    //setSelectedOfferType(false)
    handleWalletPurchaseData({})
    setActivateNow(false)
    setTransactionStatus({ status: 0, statusMsg: '' })
    if (
      pathname.split('/').includes('gift') ||
      pathname.split('/').includes('dct') ||
      pathname.split('/').includes('payment')
    ) {
      activationModalLocation === 'MY_PAGE' ? navigate('/mypage') : navigate('/shop')
    }
  }

  const handleClose = () => {
    if (showDetailsScreen) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Closed offer details screen`,
          properties: {
            offer: segmentData,
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': 'Shop',
            'purchase type': purchaseType === 'self' ? 'Buy' : 'Gift',
          },
        },
        userLogin,
      )
    }

    if (userLogin && !showDetailsScreen) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Closed ${purchaseType === 'self' ? 'Buy' : 'Gift'} popup`,
          properties: {
            offer: segmentData,
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': selectedOfferType ? 'Shop/Resale' : 'Shop',
          },
        },
        userLogin,
      )
    }
    if (purchaseType === PURCHASE_TYPE_TOPUP) {
      setRefetchMydata(true)
      setShowTopUpModal(false)
    }
    if (cryptoPurchaseLoading && paymentType === PAYMENT_TYPE[0].value) {
      setOpenConfirmModal(true)
    } else {
      resetStates()
      setShowPurchaseModal(false)
    }
    if (
      localStorage.getItem('isFeedbackDone') !== 'true' &&
      pathname.split('/').includes('gift') === false &&
      pathname.split('/').includes('dct') === false &&
      pathname.split('/').includes('payment') === false
    ) {
      setShowGuideModal(true)
      setbuyModalCloseSurvey('BUYMODAL_CLOSE_POPUP')
    }
    setIsTopup(false)
    setShowDetailsScreen(true)
    setPurchaseOrderType(PURCHASE_TYPE_SINGLE_BUY)
    setPurchaseQuantity(1)
  }

  const onConfirmModalClose = () => {
    resetStates()
    setIsTopup(false)
    setCryptoPurchaseLoading(false)
    setDisableModal(false)
    setShowPurchaseModal(false)
    setOpenConfirmModal(false)   
  }
  
  useEffect(() => {
    if (apiState === API_STATE.READY && userLogin !== null) {
      if (pathname.split('/').includes('gift')) {
        setShowDetailsScreen(false)
        setShowPurchaseModal(true)
        setPurchaseModalType(PURCHASE_TYPE_GIFT)
        getActivationCode()
      } else if (pathname.split('/').includes('payment')) {
        setShowDetailsScreen(false)
        const id = pathname.split('/')[2]

        setShowPurchaseModal(true)

        if (id === undefined || id === '') {
          handleClose()
        } else {
          statusCheck(id)
        }
        // getPaymentById(id)
      } else if (pathname.split('/').includes('dct')) {
        setShowDetailsScreen(false)
        setShowPurchaseModal(true)
        setPurchaseModalType(PURCHASE_TYPE_BUY)
        getActivationCode()
      } else if (pathname.split('/').includes('resaleOffer')||pathname.split('/').includes('offer')) {
        handleResale()
      }
    }
  }, [pathname, apiState, userLogin])
  
  const handleResale = () => {   
    
    const id = pathname.split('/')[2]
    resaleClient.getResalePackDetailsById(userLogin,id).then((res: any) => {
      
      let offer = res?.data
      let coverageArray:any = []
      coverageArray.push(res?.coverage?res?.coverage:null)
      offer.coverage = coverageArray
      let manipulatedData = {
        ...offer,
        country: offer?.regionId?.countryCode,
        color: offer?.regionId?.countryColor?.metadata,
        flagCountryCode: offer?.regionId?.flagCountryCode,
        quantity: 1,
        }
      modalDataHandler({
        data: manipulatedData,
        modalCountryData: offer,
      })
      setPurchaseModalLoading(false)
      setShowDetailsScreen(true)
      setShowPurchaseModal(true)
      setPurchaseModalType(PURCHASE_TYPE_BUY)
      setSelectedOfferType(pathname.split('/').includes('resaleOffer')?true:false)
    }).catch((e) => {
      setPurchaseModalLoading(false)
      setSelectedOfferType(false)
      setShowPurchaseModal(false)
    })    
  }
  useEffect(() => {
    setSelectedPayment({ value: 'Select your Blockchain' })
  }, [cryptos, userLogin])

  useEffect(() => {
    setCryptoData({})
  }, [selectedPayment])

  useEffect(() => {
    hideCoinbasePayment&&hideCirclePayment&&hideCTOPayment?setPaymentType('creditcard'):
    hideCardPayment&&hideCirclePayment?setPaymentType('coinbase'):
    hideCardPayment&&hideCoinbasePayment?setPaymentType('crypto'):
    hideCardPayment&&hideCTOPayment?setPaymentType('crypto'):
    hideCirclePayment&&hideCoinbasePayment?setPaymentType('cto'):
    hideCTOPayment&&hideCoinbasePayment?setPaymentType('creditcard'):
    hideCirclePayment?setPaymentType('coinbase'):
    hideCoinbasePayment?setPaymentType('creditcard'):
    setPaymentType('crypto')
    setESimQuantity(modalData?.quantity)
    handleCoverageData()
  }, [modalData])

  const statusCheck = async (id: any) => {
    const status = searchParams.get('status')

    if (status === 'success') {
      setPaymentId(id)
      setPurchaseModalLoading(false)
      setPaymentSuccess(true)
    } else {
      setPurchaseModalLoading(true)
    }
  }

  const updateQuatityOfSim = async (event: SelectChangeEvent)=>{
    setESimQuantity(event.target.value);
    (Number(event.target.value) === 1)?setPurchaseOrderType(PURCHASE_TYPE_SINGLE_BUY):setPurchaseOrderType(PURCHASE_TYPE_BULK_BUY);
   // if(Number(event.target.value) > 1) setPaymentType(BULK_PAYMENT_TYPE[0].value);
    setPurchaseQuantity(Number(event.target.value));
    let _tempModalData = modalData;
    _tempModalData.quantity = Number(event.target.value);
    _tempModalData.totalPurchaseAmount = (Number(packPrice) * Number(event.target.value));
    modalDataHandler({
      data: _tempModalData,
      modalCountryData: modalCountryData,
    })    
  }

  const handleCoverageData = async () => {
    let temp = await modalData.coverage?.filter((coverage: any) => coverage.poolId === modalData.poolId)
    setFilteredCoverage(temp?temp:[])
  }

  useEffect(() => {
    if (status === 'failed' && apiRegions.length && userLogin !== null) {
      const id = pathname.split('/')[2]

      paymentClient.getPaymentsById(id, userLogin).then((res: any) => {
        let selectedRegion = res?.offerId !== undefined ? res.offerId.regionId : res?.resaleOffer?.regionId

        if (apiRegions.length) {
          let filteredRegion = apiRegions.filter((region: any) => region._id === selectedRegion)
          filteredRegion.forEach((offer: any) => {
            let spreadData = res.offerId !== undefined ? res?.offerId : res?.resaleOffer
            let finalData = {
              ...spreadData,
              country: offer?.countryCode,
              color: offer?.countryColor?.metadata,
              flagCountryCode: offer?.flagCountryCode,
              quantity : Number(eSimQuantity),
              totalPurchaseAmount : (Number(packPrice) * Number(eSimQuantity))
            }

            modalDataHandler({
              data: finalData,
              modalCountryData: res?.offerId !== undefined ? res?.offerId : res?.resaleOffer,
            })

            setPurchaseModalLoading(false)
          })
        }
      })
    }
  }, [apiRegions, status, userLogin])

  useEffect(() => {
    if (purchaseType && modalData && modalData?.country !== '') {
      if (userLogin) {
        userClient.postUserEventsWithAuth(
          {
            eventName: `${purchaseType === 'self' ? 'Buy' : 'Gift'} data popup`,
            properties: {
              build: process.env.REACT_APP_CURRENT_CHAIN,
              offer: segmentData,
              default: paymentType !== PAYMENT_TYPE[0].value ? 'Credit Card' : 'USDC',
              'screen name': `${pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
            },
          },
          userLogin,
        )
      }
    }
  }, [modalData])

  useEffect(() => {
    if ((connectedAccount?.address !== 'undefined' || isWagmiConnected) && userLogin !== null && showPurchaseModal) {
      getCreditsValue()
    }
  }, [connectedAccount, userLogin, showPurchaseModal])

  const getActivationCode = () => {
    setActivationLoading(false)
    setPurchaseModalLoading(true)
    purchaseclient
      .getPurchaseByCode(
        pathname.split('/').includes('gift') || pathname.split('/').includes('dct')
          ? pathname.split('/').pop()
          : walletPurchaseData?.purchaseHash,
        userLogin,
      )
      .then((res: any) => {
        setRegionData(res?.region)
        handleWalletPurchaseData(res?.purchase)
        if (res?.purchase?.dctStatus === 'active') {
          checkDCTStatus({ id: res?.purchase?.id, poolId: res?.purchase?.poolId, token: userLogin }).then(
            (val: any) => {
              if (val.status === true) {
                // consumption or QR based on mycardplan action
                if (myPlanCardAction === 'SHOW_QR') {
                  setAndroidSteps({
                    step1: true,
                    step2: true,
                    step3: true,
                  })
                  setIosSteps({
                    iosStep1: true,
                    iosStep2: true,
                    iosStep3: true,
                  })
                  setDisableVerificationButton(true)
                  setPurchaseModalLoading(false)
                  setReceiverState('SCAN')
                } else {
                  setPurchaseModalLoading(false)
                  setReceiverState('ALREADY_ACTIVATED')
                }
              } else {
                // QR
                setPurchaseModalLoading(false)
                if (
                  res?.purchase?.dctStatus === 'active' &&
                  (res?.purchase?.esimStatus?.toLowerCase() === 'inactive' || !res?.purchase?.esimStatus) &&
                  !res?.purchase?.iccid
                ) {
                  if (pathname.split('/').includes('dct')) {
                    setActiveStep(BUY)
                    setPaymentSuccess(true)
                    setActivateNow(true)
                  } else {
                    setReceiverState('ACTIVATION')
                  }
                } else {
                  setReceiverState('SCAN')
                }
              }
            },
          )
        } else if (res?.purchase?.dctStatus === 'minted' || res?.purchase?.dctStatus === 'inactive') {
          //activation
          setPurchaseModalLoading(false)
          if (pathname.split('/').includes('dct')) {
            setActiveStep(BUY)
            setPaymentSuccess(true)
            setActivateNow(true)
          } else {
            setReceiverState('ACTIVATION')
          }
        } else {
          setPurchaseModalLoading(false)
          setReceiverState('ALREADY_ACTIVATED')
        }
      })
      .catch((err) => {
        setPurchaseModalLoading(false)
        toast.error(
          pathname.split('/').includes('gift')
            ? 'Invalid gift code! Please try again.'
            : 'Invalid dct code! Please try again.',
        )
        handleClose()
        navigate('/shop')
      })
  }

  const getStatusOfDct = (data: any) => {
    purchaseclient
      .getPurchaseByCode(
        pathname.split('/').includes('gift') || pathname.split('/').includes('dct')
          ? pathname.split('/').pop()
          : walletPurchaseData?.purchaseHash,
        userLogin,
      )
      .then((res: any) => {
        handleWalletPurchaseData(res?.purchase)

        if (
          res?.purchase?.dctStatus === 'minted' ||
          res?.purchase?.dctStatus === 'inactive' ||
          !res?.purchase?.activationCode
        ) {
          setTimeout(() => {
            getStatusOfDct(res)
          }, 2000)
        } else {
          setReceiverState('SCAN')
          setActivationLoading(false)
        }
      })
  }

  const activateGift = (enableQRbutton: any) => {
    setActivationLoading(true)
    if (enableQRbutton === true) {
      activateProvider(walletPurchaseData?.id, userLogin)
        .then((val: any) => {
          getStatusOfDct(val)
        })
        .catch((e) => {
          setActivationLoading(false)
          toast.error(e?.response?.data?.message)
          // console.log('Error activating DCT--->', e?.response?.data?.message)
        })
    } else {
      const code = connectedAccount.address === undefined &&
      (pathname.split('/').includes('gift') || pathname.split('/').includes('dct'))
      ? pathname.split('/').pop()
      : walletPurchaseData?.purchaseHash
      const param = { code : code }
      activateDctV3(
        param,
        userLogin,
        null
      )
        .then((val: any) => {
          getStatusOfDct(val)
        })
        .catch((e) => {
          setActivationLoading(false)
          toast.error(e?.response?.data?.message)
          // console.log('Error activating DCT--->', e?.response?.data?.message)
        })
    }
  }

  const packSize = modalData?.quantityOfDataInBytes
    ? (modalData?.quantityOfDataInBytes / (1024 * 1024) / 1024).toString()
    : (walletPurchaseData?.dataLimitInBytes / (1024 * 1024) / 1024).toString()
  let dataInGB = packSize.length > 3 ? parseFloat(packSize).toFixed(2) : packSize

  const provider = allProviders?.find((item: any) => {
    return item.id === modalData?.providerId
  })

  const filterCoveragetworks = (limit: any) => {
    let filterValueofCoverage = filteredCoverage[0]?.coverage?.map((item: any) =>
      item?.networks?.map((network: any) => network?.name),
    )
    return limit === 0 ? filterValueofCoverage : filterValueofCoverage ? filterValueofCoverage[0][0] : null
  }

  const handleOpenNetworkModal = (type: string) => {
    setShowNetworkModal({
      data: filteredCoverage,
      type: type,
      isOpen: true,
      offer: modalData,
    })
  }

  const getCreditsValue = async () => {
    let finalCredits = await getCreditsFromChain(polkadotAccountAddress?polkadotAccountAddress:connectedAccount?.address)
    let temp = toDecimal(finalCredits).deci
    setCredits(temp)
  }

  const handleCreditPayment = async () => {
    userClient.postUserEventsWithAuth(
      {
        eventName: 'Clicked Buy Button',
        properties: {
          build: process.env.REACT_APP_CURRENT_CHAIN,
          offer: segmentData,
          'screen name': 'Shop/Resale',
          'purchase type': purchaseType === 'self' ? 'Buy' : 'Gift',
        },
      },
      userLogin,
    )
    createCreditPayment(
      {
        navigate,
        userLogin,
        segmentData,
        userDetails,
        pathname,
        paymentType,
        modalData,
        purchaseType,
        selectedOfferType,
        setIsPaymentLoading,
        userEmail,
        setCheckoutUrl,
        setCheckoutSuccess,
        setTransactionStatus
      },
      true,
    )
  }

  const handleCloseConfirmDetails = () => {
    if(isConnected || purchaseOrderType === PURCHASE_TYPE_SINGLE_BUY){
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Choose payment method button',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData,
            'screen name': selectedOfferType ? 'Shop/Resale' : 'Shop',
            'purchase type': purchaseType === 'self' ? 'Buy' : 'Gift',
          },
        },
        userLogin,
      )
      setShowDetailsScreen(false)
    }else{
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Buy with crypto (Bulk) button',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData,
            'screen name': selectedOfferType ? 'Shop/Resale' : 'Shop',
            'purchase type': purchaseType === 'self' ? 'Buy' : 'Gift',
          },
        },
        userLogin,
      )
     // hideCirclePayment?setShowDetailsScreen(false):setConnectedModalOpen(true);
     setShowDetailsScreen(false)
    }

  }

  const getCreditUsedValue = (price: any) => {
    let packPrice = price
    let creditsValue = parseFloat(credits)
    let temp = packPrice - creditsValue

    if (temp > 1) {
      return creditsValue?.toFixed(2)
    } else if (temp < 0) {
      return parseFloat(packPrice)?.toFixed(2)
    } else if (temp > 0){
      return (0)
    } else {
      return (packPrice - 1).toFixed(2)
    }
  }

  return (
    <>
      <Modal open={showPurchaseModal}>
        <>
          {purchaseModalLoading ? (
            <S.ModalMainContainer sx={{ display: 'grid', placeContent: 'center', minHeight: '508px' }}>
              <CircularProgress />
            </S.ModalMainContainer>
          ) : (
            <S.ModalMainContainer>
              <S.PurchaseInfoHeaderContainer
                sx={{
                  background: `linear-gradient(113.74deg, ${
                    modalData?.color?.gradientStart
                      ? modalData?.color?.gradientStart
                      : regionData?.countryColor?.metadata?.gradientStart
                      ? regionData?.countryColor?.metadata?.gradientStart
                      : filteredOffer?.regionId?.countryColor?.metadata?.gradientStart
                      ? filteredOffer?.regionId?.countryColor?.metadata?.gradientStart
                      : '#CFEBE1'
                  } 9.6%,  ${
                    modalData?.color?.gradientEnd
                      ? modalData?.color?.gradientEnd
                      : regionData?.countryColor?.metadata?.gradientEnd
                      ? regionData?.countryColor?.metadata?.gradientEnd
                      : filteredOffer?.regionId?.countryColor?.metadata?.gradientEnd
                      ? filteredOffer?.regionId?.countryColor?.metadata?.gradientEnd
                      : '#F0F9F6'
                  } 91.34%)`,
                }}
              >
                <S.ModalHeader>
                {modalData?.quantityOfDataInBytes || walletPurchaseData?.dataLimitInBytes? 
                <S.ModalSubHeader>
                    <div style={{ display: 'flex', gap: '0.3rem' }}>
                      <S.ModalHeading>{dataInGB}</S.ModalHeading>
                      <S.ModalSubHeadingGB>GB</S.ModalSubHeadingGB>
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                      <Box sx={{ fontWeight: 900 }}>|</Box> &nbsp;
                      <>
                        {!selectedOfferType ? (
                          <S.ModalHeaderCoverageMap>
                            {modalData?.validFromActivation
                              ? modalData?.validFromActivation
                              : walletPurchaseData?.offer?.validFromActivation
                              ? walletPurchaseData?.offer?.validFromActivation
                              : filteredOffer?.validFromActivation}{' '}
                            {(modalData.validFromActivation
                              ? modalData.validFromActivation
                              : walletPurchaseData?.offer?.validFromActivation
                              ? walletPurchaseData?.offer?.validFromActivation
                              : filteredOffer?.validFromActivation) === 1 ||
                            (modalData.validFromActivation
                              ? modalData.validFromActivation
                              : walletPurchaseData?.offer?.validFromActivation
                              ? walletPurchaseData?.offer?.validFromActivation
                              : filteredOffer?.validFromActivation) === '1'
                              ? t('puchase_modal.day')
                              : t('puchase_modal.days')}{' '}
                            &nbsp;
                          </S.ModalHeaderCoverageMap>
                        ) : (
                          <S.ModalHeaderCoverageMap>
                            {modalData?.validity
                              ? modalData?.validity
                              : walletPurchaseData?.offer?.validity
                              ? walletPurchaseData?.offer?.validity
                              : filteredOffer?.validity
                              ? filteredOffer?.validity
                              : filteredOffer?.validFromActivation}{' '}
                            {(modalData.validity
                              ? modalData.validity
                              : walletPurchaseData?.offer?.validity
                              ? walletPurchaseData?.offer?.validity
                              : filteredOffer?.validity
                              ? filteredOffer?.validity
                              : filteredOffer?.validFromActivation) === 1 ||
                            (modalData.validity
                              ? modalData.validity
                              : walletPurchaseData?.offer?.validity
                              ? walletPurchaseData?.offer?.validity
                              : filteredOffer?.validity
                              ? filteredOffer?.validity
                              : filteredOffer?.validFromActivation) === '1'
                              ? t('puchase_modal.day')
                              : t('puchase_modal.days')}{' '}
                            &nbsp;
                          </S.ModalHeaderCoverageMap>
                        )}
                      </>
                    </Box>
                  </S.ModalSubHeader>
                  :null}
                  {modalData?.quantityOfDataInBytes || walletPurchaseData?.dataLimitInBytes? 
                  <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <S.ModalHeaderCountry>
                      {modalData?.country
                        ? modalData?.country
                        : regionData?.countryCode
                        ? regionData?.countryCode
                        : filteredOffer?.region}
                    </S.ModalHeaderCountry>
                    {countriesWithPlaceholderFlag.includes(filteredOffer?.regionId?.flagCountryCode) ||
                    countriesWithPlaceholderFlag.includes(modalData?.flagCountryCode) ||
                    countriesWithPlaceholderFlag.includes(regionData?.flagCountryCode) ? (
                      <img src={GlobeIcon} alt="currency img" style={{ height: '2rem' }} />
                    ) : (<>
                      {modalData?.flagCountryCode ||regionData?.flagCountryCode || filteredOffer?.regionId?.flagCountryCode ?
                      <img
                        alt="country flag"
                        style={{ height: '2rem', width: '2rem' }}
                        src={`https://giant-protocol.github.io/country-flag-icons/3x2/${
                          modalData?.flagCountryCode
                            ? modalData?.flagCountryCode?.toUpperCase()
                            : regionData?.flagCountryCode?.toUpperCase()
                            ? regionData?.flagCountryCode?.toUpperCase()
                            : filteredOffer?.regionId?.flagCountryCode?.toUpperCase()
                        }.svg`}
                      />:null}
                      </>)}
                  </Box>:null}
                </S.ModalHeader>
                <S.CustomIconButton
                  onClick={handleClose}
                  sx={{
                    alignItems: `${medium && 'flex-start'}`,
                    padding: `${medium && '1rem 1rem 0 0'}`,
                  }}
                >
                  <CloseIcon
                    sx={{
                      border: '1px solid #DEE7FD',
                      background: '#FFFFFF',
                      padding: 'unset !important',
                    }}
                  />
                </S.CustomIconButton>
              </S.PurchaseInfoHeaderContainer>

              {showDetailsScreen ? (
                <S.ModalBody>
                  <S.DetailsContainer>
                    {medium1 && (
                      <S.ImgContainer medium1={medium1}>
                        <S.DetailsIllustrationImg medium1={medium1} src={DetailsIllustration} alt="illustration" />
                      </S.ImgContainer>
                    )}
                    <S.ContentContainer>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '1.5625rem 0',
                          justifyContent: medium1 ? 'space-between' : 'start',
                          alignItems: 'center',
                        }}
                      >
                        <S.DataWrapper>
                          <S.DetailsMainText>{t('puchase_modal.provider')}:</S.DetailsMainText>
                          <S.DetailsSubText sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <img
                              src={`data:image/png;base64,${provider?.logo}`}
                              alt="logo"
                              style={{ width: '0.9706rem', height: '1rem' }}
                            />
                            {provider?.displayName.length > 10
                              ? `${provider?.displayName.slice(0, 6)}...`
                              : provider?.displayName}
                          </S.DetailsSubText>
                        </S.DataWrapper>
                        <S.DataWrapper>
                          <S.DetailsMainText>{t('puchase_modal.connection')}:</S.DetailsMainText>
                          <S.DetailsSubText>{modalData?.connectionType}</S.DetailsSubText>
                        </S.DataWrapper>
                        <S.DataWrapper style={{ order: medium && '1' }}>
                          <S.DetailsMainText>{t('puchase_modal.validity')}:</S.DetailsMainText>
                          {modalData?.activationPeriod === 0 ? (
                            <S.DetailsSubText>
                              {selectedOfferType ? modalData?.validity : modalData?.validFromActivation} {t('puchase_modal.days-from-today')}
                            </S.DetailsSubText>
                          ) : (
                            <S.DetailsSubText>
                              {selectedOfferType ? modalData?.validity : modalData?.validFromActivation} {t('puchase_modal.days-from-activation')}
                            </S.DetailsSubText>
                          )}
                        </S.DataWrapper>
                        <S.DataWrapper>
                          <S.DetailsMainText>{t('puchase_modal.network')}:</S.DetailsMainText>
                          <S.DetailsSubText>
                            {modalData?.type !== 'self' ? (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <S.DetailsSubText sx={{ maxWidth:'145px',display:'inline !important',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis' }} title={filterCoveragetworks(1)}>{filterCoveragetworks(1)}</S.DetailsSubText> &nbsp;
                                {filterCoveragetworks(0) && (
                                  <S.DetailsSubText
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => handleOpenNetworkModal('network')}
                                  >
                                    (<Typography sx={{ color: '#45B549' }}>+</Typography>)
                                  </S.DetailsSubText>
                                )}
                              </Box>
                            ) : (
                              <CustomTooltip message={filterCoveragetworks(0)}>
                                <S.DetailsSubText>{filterCoveragetworks(0)} </S.DetailsSubText>
                              </CustomTooltip>
                            )}
                          </S.DetailsSubText>
                        </S.DataWrapper>
                        <S.DataWrapper>
                          <S.DetailsMainText>{t('puchase_modal.services')}:</S.DetailsMainText>
                          <S.DetailsSubText>{t('puchase_modal.data-only')}</S.DetailsSubText>
                        </S.DataWrapper>
                        <S.DataWrapper style={{ order: medium && '2' }}>
                          <S.DetailsMainText>{t('puchase_modal.activation')}:</S.DetailsMainText>
                          {modalData?.activationPeriod === 0 ? (
                            <S.DetailsSubText>{t('puchase_modal.activated-already')}</S.DetailsSubText>
                          ) : (
                            <S.DetailsSubText>{modalData?.activationPeriod} {t('puchase_modal.days-to-activate')}</S.DetailsSubText>
                          )}
                        </S.DataWrapper>
                        {modalData?.activationPeriod !== 0 && (
                          <S.DataWrapper style={{ order: medium && '4' }}>
                            <S.DetailsMainText>{t('puchase_modal.resell-enabled')}:</S.DetailsMainText>
                            {!selectedOfferType ? (
                              <S.DetailsSubText>
                                {modalData?.poolId !== 1
                                  ? 'Yes (Unactivated Only) '
                                  : purchaseType === PURCHASE_TYPE_BUY
                                  ? 'Yes'
                                  : 'No'}
                              </S.DetailsSubText>
                            ) : (
                              <S.DetailsSubText>No</S.DetailsSubText>
                            )}
                          </S.DataWrapper>
                        )}
                        <S.DataWrapper style={{ order: medium && '3' }}>
                          <S.DetailsMainText>{t('puchase_modal.devices')}:</S.DetailsMainText>
                          <S.DetailsSubText>{t('puchase_modal.req-comp-device')}</S.DetailsSubText>
                        </S.DataWrapper>
                      </Box>
                      <S.DetailsGreyText>
                      {t('puchase_modal.please-ensure')}
                        <br />
                        {t('puchase_modal.to-verify')}&nbsp;
                        <Link
                          target={'_blank'}
                          sx={{ textDecoration: 'none', fontsize: 700 }}
                          href="https://support.giantprotocol.org/hc/en-us/articles/9297361603351-How-do-I-know-if-my-device-supports-eSIM-"
                        >
                          {t('puchase_modal.esim-faq')}&nbsp;
                        </Link>
                        <br />
                        {t('puchase_modal.activated-esim-cannot')}
                      </S.DetailsGreyText>

                      {medium1 && (
                        <S.PayEarnMainContainer>
                          <S.PayEarnContainer medium1={medium1}>
                            <S.DetailsPayText>
                              {t('shop_plancard-pay')} $
                              {selectedOfferType
                                ? Number(modalData?.resalePrice)?.toFixed(2)
                                : Number(modalData?.retailPrice)?.toFixed(2)}
                            </S.DetailsPayText>
                            <S.DetailsEarnText>
                              {t('shop_plancard-earn')} <img src={GiantDollorGreen} alt="" />
                              {selectedOfferType ? '0.00' : ((Number(modalData?.retailPrice) / 100) * 40).toFixed(2)}
                            </S.DetailsEarnText>
                          </S.PayEarnContainer>
                        </S.PayEarnMainContainer>
                      )}

                      <S.ChoosePaymentContainer>
                        <PrimaryButton
                          style={{
                            height: '2.75rem',
                            width: '16.5625rem',
                            alignSelf: medium1 ? 'center' : 'flexStart',
                          }}
                          onClick={() => {
                            selectedOfferType
                              ? Number(modalData?.resalePrice) - parseFloat(credits) >= 0
                                ? handleCloseConfirmDetails()
                                : handleCreditPayment()
                              : Number(modalData?.totalPurchaseAmount) - parseFloat(credits)  >= 0 //Number(modalData?.retailPrice) - parseFloat(credits)
                              ? handleCloseConfirmDetails()
                              : handleCreditPayment()
                          }}
                          // onClick={() => (true ? handleCreditPayment() : handleCloseConfirmDetails())}
                          disable={isPaymentLoading}
                        >
                          {isPaymentLoading ? (
                            <CircularProgress size={25} />
                          ) : (
                            (eSimQuantity <= 1)?(//Added for bulk payment
                              <>
                                {selectedOfferType
                                  ? Number(modalData?.resalePrice) - parseFloat(credits) >= 0
                                    ? t('puchase_modal-button.choose-payment-method')
                                    : t('puchase_modal-button.buy')
                                  : Number(modalData?.retailPrice) - parseFloat(credits) >= 0
                                  ? t('puchase_modal-button.choose-payment-method')
                                  : t('puchase_modal-button.buy')}
                              </>
                            ):(
                              <>
                                {selectedOfferType
                                  ? Number(modalData?.resalePrice) - parseFloat(credits) >= 0
                                    ? t('puchase_modal-button.buy-with-crypto')
                                    : t('puchase_modal-button.buy')
                                  : Number(modalData?.totalPurchaseAmount) - parseFloat(credits) >= 0
                                  ? t('puchase_modal-button.buy-with-crypto')
                                  : t('puchase_modal-button.buy')}
                              </>
                            )

                          )}
                        </PrimaryButton>
                        <S.DetailsGreyTextTerm>
                        {t('puchase_modal-terms')}
                        </S.DetailsGreyTextTerm>
                      </S.ChoosePaymentContainer>
                    </S.ContentContainer>
                    <S.ImgContainer>
                      <S.DetailsIllustrationImg src={DetailsIllustration} alt="illustration" />
                      <S.PayEarnContainer>
                        <S.CreditsPayContainer>
                          <S.CreditsPayText>{t('puchase_modal.count')}:</S.CreditsPayText>
                          <Typography>
                            <S.CustomSelectBox
                              value={eSimQuantity?eSimQuantity:1}
                              label={t('puchase_modal.quantity')}
                              onChange={updateQuatityOfSim}
                              disabled={modalData.resaleType==='LISTED'||isTopup||(hideCirclePayment&&hideCoinbasePayment)||!enableBulkPurchase}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                            </S.CustomSelectBox>
                          </Typography>
                        </S.CreditsPayContainer>
                        <S.CreditsPayContainer>
                          <S.CreditsPayText>{t('puchase_modal.price')}:</S.CreditsPayText>
                          <Typography>${packPrice ? (Number(packPrice) * eSimQuantity) : '0.00'}</Typography>
                        </S.CreditsPayContainer>

                        <S.CreditsPayContainer>
                          <S.CreditsPayText sx={{ marginBottom: '1rem' }}>Credits Used:</S.CreditsPayText>
                          <Typography>${getCreditUsedValue((Number(packPrice) * eSimQuantity))}</Typography>
                        </S.CreditsPayContainer>
                        <S.DetailsPayText>{t('shop_plancard-pay')} $ {getPaymentPrice((Number(packPrice) * eSimQuantity), credits)}</S.DetailsPayText>
                        <S.DetailsEarnText>
                        {t('shop_plancard-earn')} <img src={GiantDollorGreen} alt="" />
                          {selectedOfferType ? '0.00' : (((Number(modalData?.retailPrice) * eSimQuantity ) / 100) * 40)?.toFixed(2)}
                        </S.DetailsEarnText>
                      </S.PayEarnContainer>
                    </S.ImgContainer>
                  </S.DetailsContainer>
                </S.ModalBody>
              ) : (
                <>
                  {receiverState === '' && purchaseType !== PURCHASE_TYPE_TOPUP &&<Selectors isDisable={isModalDisabled} />}
                  <S.ModalBody>
                    {activeStep === 0 && purchaseType !== PURCHASE_TYPE_TOPUP &&
                      (receiverState === '' ? (
                        <Buy
                          selectedPayment={selectedPayment}
                          setSelectedPayment={setSelectedPayment}
                          handleClose={handleClose}
                          segmentData={segmentData}
                        />
                      ) : (
                        <Receiver
                          activateGift={activateGift}
                          activationLoading={activationLoading}
                          handleClose={handleClose}
                          offer={walletPurchaseData}
                        />
                      ))}
                    {activeStep === 1 && purchaseType !== PURCHASE_TYPE_TOPUP &&(purchaseType === PURCHASE_TYPE_BUY ? <Scan /> : purchaseType === PURCHASE_TYPE_GIFT ? <Gift /> :null)}
                    {activeStep === 2 && purchaseType !== PURCHASE_TYPE_TOPUP &&<Earn handleClose={handleClose} />}
                    {purchaseType === PURCHASE_TYPE_TOPUP &&<TopupPlan handleClose={handleClose}/>}
                  </S.ModalBody>
                </>
              )}
            </S.ModalMainContainer>)}
        </>
      </Modal>
      {openConfirmModal && <ConfirmModal onConfirmModalClose={onConfirmModalClose} />}
    </>
  )
}

export default PurchaseModal
