import React, { useState, FC, useEffect } from 'react'
import { Grid, Box, Modal, Select, Button, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'

import TopupCard from 'components/cards/topupcard'
import CloseIcon from '@mui/icons-material/Close'
import { topupClient } from 'services/http/topup.service'
import { userClient } from 'services/http/user.service'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'

const TopUpModal: FC = () => {
  const { userLogin } = useWalletContext()
  const { showTopUpModal, setShowTopUpModal, topUpMetaData } = useModalContext()
  const [topups, setTopups] = useState<any>([])
  const [topupDataLoading, setTopupLoading] = useState<any>(true)
  const [topupPage, setTopupPage] = useState<number>(1)

  useEffect(() => {
    if (showTopUpModal && userLogin !== null) {
      getTopups()
    }
  }, [showTopUpModal, userLogin, topUpMetaData])

  const getTopups = () => {
    setTopups([])
    setTopupLoading(true)
    topupClient.getTopUps(userLogin, topUpMetaData?.destination, topUpMetaData?.poolId, topUpMetaData?.iccid)
      .then((res: any) => {
        let region = res?.length?res[0]:[];
        let temp = region?.offers?.map((offer: any) => {
          return {
            ...region,
            ...offer,
            countryName: region?.name,
            id: offer._id,
          }
        })
        setTopups(temp)
        setTopupLoading(false)
      })
  }
  const closePopup = ()=> { 
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Topup Modal Closed',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,            
          },
        },
        userLogin,
      )
    }
    setShowTopUpModal(false)
    }

  const fetchNextPage = async (type: string) => {
    setTopupPage((prev: number) => prev + 1)
   // getTopups()
  }

  return (
    <Modal
      open={showTopUpModal}
      onClose={() => setShowTopUpModal(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <TopupDetailsContainer>
        <TopupHeader>
          <CloseIcon
            sx={{
              borderRadius: '30%',
              background: '#45B549',
              color: 'white',
              padding: '5px !important',
              cursor: 'pointer'
            }}
            onClick={() => closePopup()}
          />
        </TopupHeader>
        <TopupContainer>
          <TopupHistory sx={{ overflow: 'auto' }}>
            {topupDataLoading ? (
              <Box sx={{ width: '100%', height: '40vh', display: 'grid', placeContent: 'center' }}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <PacksContainer removePadding={topups?.length === 0}>
              {topups?.map((offer: any, index: any) => {
              return <TopupCard key={offer.id} offer={offer} index={index} />
            })}
          </PacksContainer>
                
            )}

            {!topupDataLoading && topups?.length >= 10 && (
              <Box sx={{ display: 'grid', placeContent: 'center', margin: '1rem 0' }}>
                <LoadMoreBtn onClick={() => fetchNextPage('request')}>Load next 10</LoadMoreBtn>
              </Box>
            )}
          </TopupHistory>
        </TopupContainer>
      </TopupDetailsContainer>
    </Modal>
  )
}
export default TopUpModal

export const TopupDetailsContainer = styled(Grid)(({ theme }) => ({
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'transparent',
  borderRadius: '8px',
  outline: 'none',
  position: 'absolute',
  width: '75%',
  '@media (max-width: 865px)': {
    width: '80%',
    maxHeight: 'auto',
  },
}))

export const PacksContainer = styled(Box)(({ theme, removePadding }: any) => ({
  position: 'relative',
  margin: 'auto',
  maxWidth: '1400px',
  padding: removePadding ? 'unset' : '1.625rem 0 6.25rem 0',
  display: 'flex',
  gap: '2.3125rem',
  justifyContent: 'center',
  flexWrap: 'wrap',
}))
export const LoadMoreBtn = styled(Button)(({ theme }: any) => ({
  background: '#C4C4C4',
  height: '1.875rem',
  width: '8.5625rem',
  borderRadius: '0.5rem',
  color: theme.palette.common.white,
}))

const TopupContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '85vh',
}))

const TopupHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  background: 'none',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '1rem 2rem 1rem 4rem',  
}))

const TopupHistory = styled(Box)(({ theme }) => ({
  padding: '0px',
  background: 'transparent',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    padding: '1rem',
  },
}))

export const CustomSelect = styled(Select)(({ theme }) => ({
  textTransform: 'none',
  height: '34px',
  minWidth: '253px',
  borderRadius: '4px',
  padding: '1.25rem 1rem ',
  background: ' #F3F5F7',
  border: 'none',
  fontSize: '18px',
  fontWeight: '500',
  svg: {
    color: '#000',
    paddingRight: '0.5rem',
  },
  '@media (max-width:320px)': {
    minWidth: '200px',
  },
}))

export const PageToggleBtn = styled(Button)(({ theme }: any) => ({
  minWidth: '44px',
  height: '44px',
  borderRadius: '8px',
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  '&:hover': {
    background: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
  },
}))

export const PurchaseEarned = styled(Grid)(({ theme }: any) => ({
  borderLeft: `1px solid ${theme.palette.grey[700]}`,
  padding: '0 6rem',
  borderRight: `1px solid ${theme.palette.grey[700]}`,
  flexWrap: 'wrap',

  '@media (max-width:1450px)': {
    padding: '0 3rem',
  },

  '@media (max-width:1300px)': {
    padding: '0 2rem',
  },
  '@media (max-width:1250px)': {
    padding: '0 1rem',
  },
  '@media (max-width:960px)': {
    padding: '0 1.5rem',
    borderRight: 'none',
  },
  '@media (max-width:700px)': {
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
  },
  '@media (max-width: 560px)': {
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
  },
}))
