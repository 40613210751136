import BigNumber from 'bignumber.js'
import { apiProvider } from 'polkadot'
import { currencyToNumber, toDecimal } from 'utils'
import { getStakerByAddress } from '../staking'

export const getCurrentRewardDemand = async () => {
  try {
    let res = await apiProvider.query.reward.currentDemandRewards()
    let reward = !res.isEmpty ? JSON.parse(res) : 0
    let deciRewardDemand = toDecimal(reward, 6).full
    return deciRewardDemand
  } catch (e) {
    return 0.4
  }
}
export const getAllProviders = async () => {
  try {
    let res = await apiProvider.query.provider.poolCounter()
    let providers = !res.isEmpty ? JSON.parse(res) : 0
    return providers
  } catch (e) {
    return 0
  }
}

export const getActivePoolIds = async () => {
  try {
    const activePoolIds = await apiProvider.query.provider.activePools()
    return activePoolIds.isEmpty ? Promise.resolve([]) : activePoolIds.toHuman()
  } catch (e) {
    console.log(e)
    return null
  }
}

const getPoolSizes = async () => {
  const allEntries = await apiProvider.query.staking.stakingProviderPool.entries()
  return allEntries.map(
    ([
      {
        args: [era, nominatorId],
      },
      value,
    ]: any) => {
      let poolId = era.isEmpty ? null : era.toHuman()
      return { value: value.toHuman(), poolId: poolId }
    },
  )
}

const getTvoForAllPools = async () => {
  const allEntries = await apiProvider.query.provider.poolStateInfo.entries()
  return allEntries.map(
    async ([
      {
        args: [era, nominatorId],
      },
      value,
    ]: any) => {
      let poolId = era.isEmpty ? null : era.toHuman()
      return {
        poolId: poolId,
        value: value.toHuman(),
      }
    },
  )
}

const getProviderCollateral = async () => {
  const allEntries = await apiProvider.query.provider.providerCollateralAccount.entries()
  return allEntries.map(
    async ([
      {
        args: [era, nominatorId],
      },
      value,
    ]: any) => {
      let poolId = era.isEmpty ? null : era.toHuman()
      let address = value.isEmpty ? null : value.toHuman()

      const creditAmount = await apiProvider.query.credits.account(address)
      let amount = creditAmount.isEmpty ? 0 : creditAmount.toHuman()
      return {
        poolId: poolId,
        value: amount?.free ? currencyToNumber(amount.free) : 0,
      }
    },
  )
}

const getYieldEscrow = async () => {
  const allEntries = await apiProvider.query.provider.poolYieldEscrow.entries()
  return allEntries.map(
    async ([
      {
        args: [era, nominatorId],
      },
      value,
    ]: any) => {
      let poolId = era.isEmpty ? null : era.toHuman()
      let address = value.isEmpty ? null : value.toHuman()

      const creditAmount = await apiProvider.query.credits.account(address)
      let amount = creditAmount.isEmpty ? 0 : creditAmount.toHuman()
      return {
        poolId: poolId,
        value: amount?.free ? currencyToNumber(amount.free) : 0,
      }
    },
  )
}

export namespace providers {
  export const getProviderInfo = async () => {
    let allTvos = await getTvoForAllPools()
    let tvos = await Promise.all(allTvos)
    let totalPoolSize = 0
    let poolSize = await getPoolSizes()
    let poolSizeTotal = poolSize.map((pool: any) => {
      let _pool = { ...pool }
      _pool.value = pool?.value?.total ? currencyToNumber(pool?.value?.total) : 0
      return _pool
    })

    let collateralAmountPromises = await getProviderCollateral()
    let collateralAmount = await Promise.all(collateralAmountPromises)

    let yieldEscrowPromises = await getYieldEscrow()
    let yieldEscrow = await Promise.all(yieldEscrowPromises)

    let sumOfPoolAmounts = poolSizeTotal.map((pool: any) => {
      // pool.poolId

      let cAmount = collateralAmount.find((collateral: any) => {
        return pool.poolId === collateral.poolId
      })

      let yAmount = yieldEscrow.find((yieldEscrow: any) => {
        return pool.poolId === yieldEscrow.poolId
      })

      return {
        poolId: pool.poolId,
        value: new BigNumber(cAmount.value).plus(pool.value).plus(yAmount.value).toNumber(),
      }
    })

    totalPoolSize = sumOfPoolAmounts.reduce(
      (prev: any, curr: any) => new BigNumber(prev).plus(curr.value).toString(),
      0,
    )

    return {
      tvo: tvos,
      totalPoolSize: totalPoolSize,
      poolSize: sumOfPoolAmounts,
      collateralAmount: collateralAmount,
      newPoolSize: poolSize,
    }
  }
  export const getUserProviderList = async (address: string) => {
    let pools = await getActivePoolIds()
    let providerList = pools.map(async (pool: any, index: number) => {
      let providerDetails = await getStakerByAddress(address, pool)
      return {
        poolId: pool,
        providerDetails: providerDetails,
      }
    })
    let providersDetails = await Promise.all(providerList)

    return providersDetails
  }
  export const getReferralPools = async () => {
    const allEntries = await apiProvider.query.provider.referralPoolInfo.entries()
    let entries = allEntries.map(
      async ([
        {
          args: [era, nominatorId],
        },
        value,
      ]: any) => {
        let poolId = era.isEmpty ? null : era.toHuman()
        return {
          poolId: poolId,
          value: value.toHuman(),
        }
      },
    )

    let referralPools = await Promise.all(entries)
    return referralPools
  }
}
